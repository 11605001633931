import { toast } from "react-toastify";
import { axiosInstance } from "../axios";

const getContentApi = async (
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.get("/get-content");
    successCallback(response);
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const updateBannerContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/banner/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};
const updateProductsContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/product/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const updatePortfolioContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/portfolio/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const updateServicesContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/services/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const updateTeamContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/team/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const updateAboutUsContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/about-us/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const sendEmailApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/send-mail", body);
    successCallback(response);
    toast.success("Success !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};

const updateSocialMediaApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/social/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};
const updatePrivacyPolicyApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/privacy/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", {autoClose: 3000});
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", {autoClose: 3000});
  }
};
const uploadFileApi = async (
  body:any
) =>{
  try {
    const response = await axiosInstance.post('/uploader/do', body);
    return response.data
  } catch (error) {
    toast.error("something went wrong! ", {autoClose: 3000});
  }
}

export {
  getContentApi,
  updateBannerContentApi,
  updateProductsContentApi,
  updatePortfolioContentApi,
  updateServicesContentApi,
  updateTeamContentApi,
  sendEmailApi,
  updateSocialMediaApi,
  updatePrivacyPolicyApi,
  updateAboutUsContentApi,
  uploadFileApi
};

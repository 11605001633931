import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "react-bootstrap";
import { ARROW_RIGHT, PRODUCT_DELIVERY } from "../../assets/images";
import styles from "./Home.module.css";
import Slider from "react-slick";
import { sendEmailApi } from "../../apis";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const Home = (props: any) => {
  const { content } = props;
  const navigate = useNavigate();
  const [openedModalName, setOpenedModalName] = useState("");
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [selectedService, setSelectedService] = useState<any>({});
  const [errors, setErrors] = useState<any>({
    nameError: "",
    emailError: "",
    phoneError: "",
    lastNameError: "",
    messageError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const search = useLocation().search;
  const sectionName = new URLSearchParams(search).get("section");

  useEffect(() => {
    if (sectionName) {
      let element = document.getElementById(sectionName);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [sectionName]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 10) {
        document
          .getElementById("site-header")
          ?.classList?.add("header-black-bg");
      } else {
        document
          .getElementById("site-header")
          ?.classList?.remove("header-black-bg");
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  const validate = () => {
    let nameRegex = /[^a-zA-Z.]/;
    let isValid = true;
    let _error = {
      nameError: "",
      emailError: "",
      lastNameError: "",
      messageError: "",
    };
    if (name.trim().length === 0) {
      isValid = false;
      _error = { ..._error, nameError: "Field cannot be empty !" };
    } else if (nameRegex.test(name.trim())) {
      isValid = false;
      _error = { ..._error, nameError: "Please enter a valid name!" };
    }
    if (lastName.trim().length === 0) {
      isValid = false;
      _error = { ..._error, lastNameError: "Field cannot be empty !" };
    } else if (nameRegex.test(lastName.trim())) {
      isValid = false;
      _error = { ..._error, lastNameError: "Please enter a valid last name!" };
    }
    if (message.trim().length === 0) {
      isValid = false;
      _error = { ..._error, messageError: "Please enter a message!" };
    }
    if (!email.includes("@") || !email.includes(".")) {
      isValid = false;
      _error = { ..._error, emailError: "Please enter a valid Email!" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSendEmail = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      sendEmailApi(
        {
          name: `${name} ${lastName}`,
          email: email,
          message: message,
        },
        () => {
          setName("");
          setMessage("");
          setEmail("");
          setLastName("");
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  const openInNewTab = (url: string) => {
    if (url) {
      window.open(url, "_blank", "noreferrer");
    }
  };

  const renderBanner = () => {
    return (
      <Row className={styles.bannerRow} id="home">
        <Container>
          <Row className={styles.bannerContentRow}>
            <div>
              <Container>
                <Row className="justify-content-center">
                  <Col xs="12" className="p-0">
                    <h1 className={styles.bannerHeading}>
                      {content?.banner?.title} <br />
                      <span className="theme-color-red">
                        {content?.banner?.subTitle}
                      </span>
                    </h1>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                  <Col xxl="6" xl="7" lg="10" md="10" sm="12" xs="12">
                    <p
                      className={`text-center text-white ${styles.beanderDescription}`}
                    >
                      {parse(content?.banner?.description || "")}
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                  <Col
                    xxl="2"
                    xl="3"
                    lg="3"
                    md="4"
                    sm="5"
                    xs="12"
                    className="d-flex flex-column align-items-center justify-content-center"
                  >
                    <Button
                      className="theme-background-red no-border no-radius pt-3 pb-3 ps-5 pe-5"
                      onClick={() => {
                        setOpenedModalName("bannerModal");
                        navigate("/");
                      }}
                    >
                      Learn More
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Row>
        </Container>
      </Row>
    );
  };

  const renderProductDelivery = () => {
    return (
      <Row className={styles.productDeliveryRow} id="product_delivery">
        <div>
          <Container>
            <Row>
              <Col xxl="4" xl="4" lg="4" md="4" sm="6">
                <h1 className="font-48 fw-bold w-50 text-uppercase">
                  {content?.product?.title}
                </h1>
              </Col>
              <Col xxl="8" xl="8" lg="8" md="8" sm="6">
                <p className="font-16 line-height-24">
                  {parse(content?.product?.description || "")}
                </p>
              </Col>
            </Row>
            <Row className={`${styles.pdItemRow}`}>
              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                xs="12"
                className={`${styles.pdItemCol}`}
              >
                <h2 className="fw-bold">{content?.product?.items[0]?.title}</h2>
                <p className="mt-4 font-14 fw-light line-height-24">
                  {parse(content?.product?.items[0]?.description || "")}
                </p>
              </Col>
              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                xs="12"
                className="d-md-flex align-items-center justify-content-center d-none"
              >
                <Image
                  src={PRODUCT_DELIVERY}
                  alt="PRODUCT_DELIVERY"
                  className="w-75"
                />
              </Col>
              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                xs="12"
                className={`${styles.pdItemCol} mt-4 mt-md-0`}
              >
                <h2 className="fw-bold">{content?.product?.items[1]?.title}</h2>
                <p className="mt-4 font-14 fw-light line-height-24">
                  {parse(content?.product?.items[1]?.description || "")}
                </p>
              </Col>
            </Row>
            <Row className="mt-0 mt-md-4 pt-4 pt-md-5 justify-content-center">
              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                xs="12"
                className={`${styles.pdItemCol} me-0 me-md-4`}
              >
                <h2 className="fw-bold">{content?.product?.items[2]?.title}</h2>
                <p className="mt-4 font-14 fw-light line-height-24">
                  {parse(content?.product?.items[2]?.description || "")}
                </p>
              </Col>
              <Col
                xxl="4"
                xl="4"
                lg="4"
                md="4"
                xs="12"
                className={`${styles.pdItemCol} ms-0 ms-md-4 mt-4 mt-md-0`}
              >
                <h2 className="fw-bold">{content?.product?.items[3]?.title}</h2>
                <p className="mt-4 font-14 fw-light line-height-24">
                  {parse(content?.product?.items[3]?.description || "")}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderPortfolio = () => {
    const settings = {
      dots: true,
      autoplaySpeed: 3000,
      autoplay: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Row className={styles.portfolioRow} id="portfolio">
        <div>
          <Container>
            <Row>
              <Col lg="4">
                <h1 className="font-48 fw-bold w-50 text-uppercase">
                  {content?.portfolio?.title}
                </h1>
                <p className="mt-4 font-14 fw-light line-height-24">
                  {parse(content?.portfolio?.description || "")}
                </p>
              </Col>
              <Col lg="8" className="mt-5 mt-lg-0">
                <Slider {...settings}>
                  {content?.portfolio?.items?.map(
                    (item: any, index: number) => {
                      return (
                        <div
                          role={"button"}
                          onClick={() => openInNewTab(item?.product_link)}
                          key={index}
                          className={styles.portfolioItem}
                        >
                          <Image src={item?.image_url} className="img-fluid" />
                        </div>
                      );
                    }
                  )}
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderServices = () => {
    return (
      <Row className={styles.serviceRow} id="services">
        <div className={styles.serviceDiv}>
          <Container>
            <Row>
              <Col lg="4" xs="12">
                <h1 className="font-48 fw-bold w-md-50 text-uppercase text-white">
                  {content?.services?.title}
                </h1>
              </Col>
              <Col lg="8" xs="12">
                <Row>
                  {content?.services?.items?.map((item: any, index: number) => {
                    return (
                      <Col key={index} md="6" xs="12" className="p-3">
                        <div className={styles.serviceItemDiv}>
                          <h2 className="fw-bold text-white">{item?.title}</h2>
                          <p className="mt-4 font-14 fw-lighter line-height-24 text-white">
                            {parse(item?.description || "")}
                          </p>
                          <Button
                            className={
                              "no-background no-border no-radius p-0 " +
                              styles.serviceItemBtn
                            }
                            onClick={() => {
                              setOpenedModalName("serviceModal");
                              navigate("/");
                              setSelectedService(item);
                            }}
                          >
                            <span className="theme-color-red">
                              More details
                            </span>{" "}
                            <Image
                              src={ARROW_RIGHT}
                              className={styles.serviceItemArrow}
                            />
                          </Button>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderAboutUs = () => {
    return (
      <Row className="mt-5 pt-5">
        <div>
          <Container>
            <Row id="about-us">
              <Col>
                <h1 className="font-48 fw-bold w-50 text-uppercase">
                  About Us
                </h1>
              </Col>
            </Row>
            {content?.aboutus?.items?.map((item: any, index: number) => {
              return index % 2 === 0 ? (
                <Row key={index} className="mt-5">
                  <Col
                    lg="3"
                    xs="12"
                    className="d-flex flex-column align-items-center"
                  >
                    <Image src={item?.image_url} className="rounded w-100" />
                  </Col>
                  <Col lg="9" xs="12">
                    <div>{parse(item?.title)}</div>
                    <p className="text-justify">{parse(item?.description)}</p>
                  </Col>
                </Row>
              ) : (
                <Row key={index} className="mt-5 mb-5">
                  <Col lg="9" xs="12">
                    <h3>{parse(item?.title)}</h3>
                    <p className="text-justify">{parse(item?.description)}</p>
                  </Col>
                  <Col
                    lg="3"
                    xs="12"
                    className="d-flex flex-column align-items-center"
                  >
                    <Image src={item?.image_url} className="rounded w-100" />
                  </Col>
                </Row>
              );
            })}
          </Container>
        </div>
      </Row>
    );
  };

  const renderTeam = () => {
    return (
      <Row className={styles.teamRow} id="team">
        <div>
          <Container>
            <Row>
              <Col>
                <h1 className="font-48 fw-bold w-50 text-uppercase">
                  {content?.team?.title}
                </h1>
              </Col>
            </Row>
            <Row>
              {content?.team?.items?.map((item: any, index: number) => {
                return (
                  <Col
                    key={index}
                    lg="3"
                    sm="6"
                    xs="12"
                    className="p-2 p-xl-0 mt-5"
                  >
                    <Image
                      src={item?.image_url}
                      width={260}
                      height={280}
                      className={styles.teamImg}
                    />
                    <h4 className="font-24 fw-bold mt-3 text-lg-start text-center">
                      {item?.title}
                    </h4>
                    <p className="p-0 m-0 font-16 theme-color-red fw-light text-lg-start text-center">
                      {parse(item?.description || "")}
                    </p>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderContactUs = () => {
    return (
      <Row className={styles.contactUsRow} id="contact_us">
        <div className={styles.contactUsDiv}>
          <Container>
            <Row>
              <Col xs="12" lg="6">
                <h1 className="font-48 fw-bold text-uppercase text-white">
                  Contact Us
                </h1>
              </Col>
              <Col xs="12" lg="6">
                <Form className="w-lg-75">
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                      setErrors({ ...errors, nameError: "" });
                    }}
                    placeholder="First Name"
                    className={styles.formItem}
                  />
                  <p className={styles.errorMsg}>{errors.nameError}</p>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(event) => {
                      setLastName(event.target.value);
                      setErrors({ ...errors, lastNameError: "" });
                    }}
                    className={styles.formItem + " mt-3"}
                  />
                  <p className={styles.errorMsg}>{errors.lastNameError}</p>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setErrors({ ...errors, emailError: "" });
                    }}
                    className={styles.formItem + " mt-3"}
                  />
                  <p className={styles.errorMsg}>{errors.emailError}</p>
                  <Form.Control
                    type="text"
                    as={"textarea"}
                    placeholder="Message"
                    value={message}
                    onChange={(event) => {
                      setMessage(event.target.value);
                      setErrors({ ...errors, messageError: "" });
                    }}
                    className={styles.formItem + " mt-3"}
                  />
                  <p className={styles.errorMsg}>{errors.messageError}</p>
                  <Button
                    onClick={onSendEmail}
                    className="theme-background-red no-border no-radius pt-3 pb-3 ps-5 pe-5 mt-5"
                  >
                    {isLoadingSubmission ? (
                      <Spinner
                        size={"sm"}
                        animation="border"
                        variant="primary"
                      />
                    ) : (
                      "Let's Talk"
                    )}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderBannerModel = () => {
    return (
      <Modal
        show={openedModalName === "bannerModal"}
        size="xl"
        backdrop
        centered
        contentClassName={styles.modalContent}
        onHide={() => setOpenedModalName("")}
      >
        <ModalHeader
          closeButton
          closeVariant="white"
          className="border-bottom-0"
        >
          <h3 className="w-100 text-center text-white">
            {content?.banner?.modalTitle}
          </h3>
        </ModalHeader>
        <ModalBody className="text-white fw-light">
          <Container>
            <Row>
              <Col xs="12" lg="6">{parse(content?.banner?.modalLeftContent || "")}</Col>
              <Col xs="12" lg="6">
                {parse(content?.banner?.modalRightContent || "")}
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xs="12" md="6" lg="3">
                <Button
                  onClick={() => {
                    setOpenedModalName("");
                    setTimeout(() => {
                      navigate(`/?section=${"contact_us"}`);
                    }, 200);
                  }}
                  className="theme-background-red no-border w-100 no-radius pt-3 pb-3"
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  const renderServiceModal = () => {
    return (
      <Modal
        show={openedModalName === "serviceModal"}
        size="xl"
        backdrop
        centered
        contentClassName={styles.modalContent}
        onHide={() => setOpenedModalName("")}
      >
        <ModalHeader
          closeButton
          closeVariant="white"
          className="border-bottom-0"
        ></ModalHeader>
        <ModalBody className="text-white fw-light">
          <Container>
            <Row>
              <Col xs="4">
                <Image src={selectedService?.modal_img} className="img-fluid" />
              </Col>
              <Col xs="8">
                <h4>{selectedService?.title}</h4>
                <p className="font-16 line-height-24 fw-light">
                  {parse(selectedService?.modal_description || "")}
                </p>
                <Button
                  onClick={() => {
                    setOpenedModalName("");
                    setTimeout(() => {
                      navigate(`/?section=${"contact_us"}`);
                    }, 200);
                  }}
                  className="theme-background-red no-border no-radius pt-3 pb-3 ps-5 pe-5"
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      {renderBanner()}
      {renderProductDelivery()}
      {renderPortfolio()}
      {renderServices()}
      {renderAboutUs()}
      {renderTeam()}
      {renderContactUs()}
      {renderBannerModel()}
      {renderServiceModal()}
    </>
  );
};

export { Home };
